import IMask from 'imask';
var Input = /** @class */ (function () {
    function Input(el) {
        var _this = this;
        this.initMask = function () {
            var field = _this.el.querySelector('input');
            var maskOptions = {
                mask: '+{7} (000) 000-00-00'
            };
            var mask = IMask(field, maskOptions);
        };
        this.el = el;
        var type = el.getAttribute('data-input');
        if (type)
            this.initMask();
    }
    return Input;
}());
export { Input, };
