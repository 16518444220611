import { Form } from "./form";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { Modal } from "./modal";
var App = /** @class */ (function () {
    function App() {
        var _this = this;
        this.init = function () {
            console.log('App Inited');
        };
        this.initForms = function (el) {
            var parent = el ? el : document;
            var els = parent.querySelectorAll('[data-form="main"]');
            els.forEach(function (item) { return new Form(item); });
        };
        this.initModals = function () {
            new Modal(_this);
        };
        this.init();
        this.initForms();
        this.initModals();
    }
    return App;
}());
export { App };
