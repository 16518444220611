import axios from "axios";
import { Input } from "./input";
var Form = /** @class */ (function () {
    function Form(el) {
        var _this = this;
        this.init = function () {
            _this.message = _this.el.querySelector('[data-form="message"]');
            _this.form = _this.el.querySelector('form');
            var checkbox = _this.el.querySelector('[data-form="agreement"]');
            var button = _this.el.querySelector('[data-form="button"]');
            var buttonExample = document.querySelector('#button-wheel');
            if (checkbox && button) {
                _this.agreement = checkbox;
                _this.button = button;
                _this.agreement.addEventListener('change', function () { return _this.toggleAgreement(); });
                _this.toggleAgreement();
            }
            _this.el.addEventListener('submit', function (e) { return _this.submitForm(e); });
            if (buttonExample) {
                buttonExample.addEventListener('click', function () {
                    _this.animateWheel();
                });
            }
            if (localStorage.getItem('modalDisabled')) {
                _this.blockWheel();
            }
        };
        this.initInputs = function () {
            var els = _this.el.querySelectorAll('[data-input]');
            els.forEach(function (item) { return new Input(item); });
        };
        this.toggleAgreement = function () {
            if (_this.agreement.checked) {
                _this.button.removeAttribute('disabled');
            }
            else {
                _this.button.setAttribute('disabled', 'disabled');
            }
        };
        this.animateWheel = function () {
            if (!_this.wheel)
                _this.wheel = _this.el.querySelector('[data-wheel="main"]');
            console.log(_this.wheel);
            console.log('Анимируем');
            _this.wheel.classList.add('animate');
            if (_this.wheel) {
                _this.wheel.addEventListener("animationend", function () {
                    _this.wheel.classList.remove('animate');
                    _this.wheel.classList.add('completed');
                    _this.showMessage();
                });
            }
        };
        this.blockWheel = function () {
            if (!_this.wheel)
                _this.wheel = _this.el.querySelector('[data-wheel="main"]');
            if (_this.wheel) {
                _this.wheel.classList.add('completed');
                _this.showMessage();
            }
        };
        this.submitForm = function (e) {
            e.preventDefault();
            var url = _this.form.getAttribute('action');
            var formData = new FormData();
            var field = _this.el.querySelector('[data-form="input"]');
            formData.append(field.name, field.value);
            _this.message.innerHTML = 'Отправка...';
            axios.post(url, formData)
                .then(function (response) {
                _this.message.innerHTML = 'Отправлено!';
                if (!localStorage.getItem('modalDisabled')) {
                    _this.animateWheel();
                }
                localStorage.setItem('modalDisabled', "true");
                _this.setMetric();
            })
                .catch(function (error) {
                console.log(error);
                _this.message.innerHTML = 'Ошибка';
            })
                .finally(function () {
                setTimeout(function () {
                    _this.message.innerHTML = 'Забрать подарок';
                }, 3000);
            });
        };
        this.showMessage = function () {
            var content = _this.el.querySelector('[data-form="content"]');
            var result = _this.el.querySelector('[data-form="result"]');
            if (content && result) {
                content.setAttribute('hidden', 'hidden');
                result.removeAttribute('hidden');
            }
        };
        this.setMetric = function () {
            // @ts-ignore
            if (window === null || window === void 0 ? void 0 : window.ym)
                window.ym(62003239, 'reachGoal', 'otpravka_form_koleso_fortuni');
        };
        this.el = el;
        this.init();
        this.initInputs();
    }
    return Form;
}());
export { Form, };
