import { Fancybox } from "@fancyapps/ui";
var Modal = /** @class */ (function () {
    function Modal(parent) {
        var _this = this;
        this.buttonId = '#button-widget';
        this.modalId = '#modal-wheel';
        this.disableModalKey = "modalDisabled";
        this.lastClosedDateKey = "lastModalCloseDate";
        this.initModal = function () {
            var link = document.querySelector(_this.buttonId);
            link.addEventListener('click', function () {
                Fancybox.show([{ src: _this.modalId, type: "inline" }], {
                    mainClass: "fancybox-wheel",
                    on: {
                        done: function (el) {
                            _this.parent.initForms(el.container);
                            _this.setMetric();
                        },
                        destroy: _this.handleModalClose
                    }
                });
            });
        };
        this.showModalAfterDelay = function () {
            setTimeout(function () {
                var isModalDisabled = localStorage.getItem(_this.disableModalKey) === "true";
                var lastClosedDate = localStorage.getItem(_this.lastClosedDateKey);
                if (isModalDisabled)
                    return;
                var oneDayInMs = 24 * 60 * 60 * 1000;
                var now = Date.now();
                if (!lastClosedDate || now - new Date(lastClosedDate).getTime() > oneDayInMs) {
                    if (!Fancybox.getInstance()) {
                        Fancybox.show([{ src: _this.modalId, type: "inline" }], {
                            mainClass: "fancybox-wheel",
                            on: {
                                done: function (el) {
                                    _this.parent.initForms(el.container);
                                    _this.setMetric();
                                },
                                destroy: _this.handleModalClose
                            }
                        });
                    }
                }
            }, 30000);
        };
        this.handleModalClose = function () {
            // const button = document.querySelector("#button");
            //
            // if (button) {
            //     button.addEventListener("click", () => {
            //         localStorage.setItem(this.disableModalKey, "true");
            //         Fancybox.close();
            //     });
            // }
            console.log('Запоминаем дату');
            if (!localStorage.getItem(_this.disableModalKey)) {
                localStorage.setItem(_this.lastClosedDateKey, new Date().toISOString());
            }
        };
        this.setMetric = function () {
            // @ts-ignore
            if (window === null || window === void 0 ? void 0 : window.ym)
                window.ym(62003239, 'reachGoal', 'pokaz_popup_koleso_fortuny');
        };
        this.parent = parent;
        this.initModal();
        this.showModalAfterDelay();
    }
    return Modal;
}());
export { Modal, };
